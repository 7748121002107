<template>
  <b-row style="background-color: #175c8e; height: 38px;">
    <b-col class="d-flex justify-content-center">
      <div class="contact-items-position" style="height: 38px; width: 100%; max-width: 1200px; display: flex;">
        <a :href="`mailto:${ this.$store.state.data.supportEmail }`" class="text-white mr-3 small no-padding h-100 d-flex align-items-center">
          <b-icon-chat style="height: 20px; width: 20px; margin-right: 4px;"></b-icon-chat>
          {{ this.$store.state.data.supportEmail }}
        </a>
        <a :href="`tel:+${ this.$store.state.data.supportPhone }`" class="blue-bar-email text-white small no-padding h-100 d-flex align-items-center">
          <b-icon-phone style="height: 20px; width: 20px; margin-right: 2px;"></b-icon-phone>
          {{ this.$store.state.data.supportPhone | capitalizeAll }}
        </a>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'the-blue-bar',

  components: {
    // BIconPhone,
  }
  
}
</script>

<style scoped>
  
  @media screen and (max-width: 768px) {
    .contact-items-position {
      justify-content: center;
    }
  }

  @media screen and (min-width: 769px) {
    .contact-items-position {
      justify-content: flex-end;
    }

    .blue-bar-email {
      margin-right: 30px;
    }
  }


</style>
