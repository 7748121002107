<template>

  <!-- Sub Nav (Controls Events) -->
  <div class="row">
    <!-- This is the root div for now -->
    <div class="sub_nav_bg">
      <div class="events_wrap">
        <div class="sub_nav">
          <ul>
            <li>
              <button class="event_toggle" @click="toggleEventsNav" :class="{active: showEvents}">

                <span class="icon_space"><img class="sub_nav_icon" src="@/assets/icons/calander_icon.png"
                    border="0" /></span>
                <span class="text_space">Events</span>
                <span v-show="eventsFutureCount" class="number_of_events">({{ eventsFutureCount }})</span>
              </button>
            </li>
            <li>
              <a target="_blank" :href="newsletterUrl">
                <span class="icon_space"><img class="sub_nav_icon" src="@/assets/icons/newsletter-icon.png"
                    border="0" /></span>
                <span class="text_space">Newsletter</span>
              </a>
            </li>
            <li>
              <a target="_blank" :href="enrollmentUrl">
                <span class="icon_space"><img class="sub_nav_icon" src="@/assets/icons/enrollment-icon.png"
                    border="0" /></span>
                <span class="text_space">Enrollment Portal</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Sub Nav (Controls Events) -->

    <!-- Events Section -->

    <transition name="openEvents">
      <div class="events_bg" v-if="showEvents">

        <div class="events_wrap inner">

          <button class="close_events" @click="showEvents = !showEvents" :class="{active: showEvents}">
          </button>

          <div class="events_header">
            <h2>Upcoming Events:</h2>
            <p v-if="eventsFutureCount > 0">There's so much to discover in the world of Honda digital advertising! Click on the links below to learn
              more about and register for these upcoming webinars available to you.</p>

            <p v-else>Nothing on the calendar at the moment.</p>
          </div>

          <div class="event">

            <ul v-for="event in eventsFuture" :key="event.event_id">
              <!-- <ul v-for="event in data" :key="event.id"> -->

              <li>
                <div class="event_banner">

                  <div class="event_category_icon">
                    <p>
                      <b-badge variant="success">{{ event.type.replaceAll('_', ' ') |
                      capitalizeAll }}</b-badge>
                    </p>
                  </div>

                  <div class="event_banner_image">
                    <img :src="filePath + event.image_url" border="0" />
                  </div>

                  <div class="event_content">
                    <div class="event_text">
                      <h2>{{ event.name }}</h2>
                      <p>{{ formatDate(event.date) }} @ {{ event.time }}</p>
                      <h6 class="event_kba mt-2"><a target="_BLANK" :href="event.registration_btn_url">{{
                      event.registration_btn_text }}</a>
                      </h6>
                    </div>

                  </div>

                </div>
              </li>

            </ul>
          </div>

        </div>
      </div><!-- End event_bg -->
    </transition>




  </div><!-- This is the end of the root div for now -->

</template>

<script>


 // import jsonData from '@/assets/json_test_files/jsonData.json'

export default {

  name: 'the-sub-nav',

  components: {
    // BIconPhone,
  },

  props: {
    newsletterUrl: String,
    enrollmentUrl: String,
  },

  data: () => ({

    showEvents: false,
    filePath: process.env.VUE_APP_FILES_PATH,
    events: {},
    eventsFuture: {},
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
  //  data: jsonData
  }),

  watch: {
    '$route.params': function ()
    {
      this.getEvents();
    },
  },

  methods: {

    formatDate(date) {
      const rawDate = new Date(date + 'T00:00:00');
      return this.dayNames[rawDate.getDay()] + ', ' + this.monthNames[rawDate.getMonth()] + ' ' + rawDate.getDate();
    },

    getEvents(){
      this.$store.commit('generateApiCreds');
      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'events', {       
          // auth: {
          //   username: this.$store.state.auth.apiUsername,
          //   password: this.$store.state.auth.apiPassword,
          // }
        })
        .then(resp => {
          this.events = resp.data;
          this.setFormattedDates();
          this.setEventsFuture();
        })
    },

    setFormattedDates() {
      for (var i = 0; i < this.events.length; i++) {
        this.events[i].dateFormatted = this.formatDate(this.events[i].date);
      }
    },

    setEventsFuture() {
      this.eventsFuture = this.events.filter(e => new Date(e.date) >= new Date());
    },

    toggleEventsNav() {
      this.showEvents = !this.showEvents;
    },

  },

  computed: {
    eventsFutureCount: function () {
      return this.eventsFuture.length;
    },
  },

}

</script>

<style scoped>
/* Animation on Events Button Click */
.openEvents-enter-active {
   -moz-transition-duration: 0.5s;
   -webkit-transition-duration: 0.5s;
   -o-transition-duration: 0.5s;
   transition-duration: 0.5s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.openEvents-leave-active {
   -moz-transition-duration: 0.5s;
   -webkit-transition-duration: 0.5s;
   -o-transition-duration: 0.5s;
   transition-duration: 0.5s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.openEvents-enter-to, .openEvents-leave {
   max-height: 1500px;
   overflow: hidden;
}

.openEvents-enter, .openEvents-leave-to {
   overflow: hidden;
   max-height: 0;
}
/* Animation on Events Button Click */

.badge {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
  -moz-box-shadow: 3px 3px 3px #000;
  -webkit-box-shadow: 3px 3px 3px #000;
  box-shadow: 3px 3px 3px #000;
  }

</style>
