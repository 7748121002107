<template>
  <the-modal class="row">

    <div class="col-12">

      <div @click="closeEverything" style="left: 0px; position: fixed; height: 100%; width: 100%;"></div>

      <div style="position: aboslute; max-width: 850px; margin-left: auto; margin-right: auto; z-index: 360; margin-top: 50px;">
        <b-icon-x id="close-x" @click="closeEverything"></b-icon-x>
        <div class="row">
          <div class="col-lg-6 bg-white left-corners" style="padding-bottom: 30px;">
            <b-img src="@/assets/call_center.jpg" class="d-none d-lg-block" style="border-radius: 5px 0px 0px 0px; max-height: 350px; margin-left: -15px;"/>
            <h1 style="font-size: 25px; font-weight: 400; margin-top: 20px;">Need some assistance?</h1>
            <p style="font-weight: 600; margin-top: -5px;">We're here to help.</p>
            <p>Have a question about Honda Digital Solutions? Contact us by phone, email or submit the form to the right. We'll get back to you ASAP.</p>
            <hr style="margin-top: 30px; margin-bottom: 30px;">
            <a :href="`tel:+${ this.$store.state.data.supportPhone }`" style="float: left; font-weight: 600; margin-left: 10px; color: #212529;">
              <b-icon-phone style="float: left; z-index: 110; height: 35px; width: 30px; margin-right: 10px; margin-top: -5px;"></b-icon-phone>
              {{ this.$store.state.data.supportPhone }}</a>
            <a :href="`mailto:${ this.$store.state.data.supportEmail }`" style="color: #212529; float: left; font-weight: 600; margin-left: 10px; margin-top: 20px;">
              <b-icon-chat style="float: left; z-index: 110; height: 35px; width: 30px; margin-right: 10px; margin-top: -5px;"></b-icon-chat>
              {{ this.$store.state.data.supportEmail }}</a>
          </div>

          <div class="col-lg-6 text-white pt-3 right-corners" style="background-color: #175c8e; padding-bottom: 30px;">
            <div>
              <div v-show="success" class="text-center" style="margin-bottom: 20px; padding: 15px 15px 15px 15px; background-color: #134a72 !important; border-radius: 10px;">
                <h1><b-icon-checkBox></b-icon-checkBox></h1>
                <h1 style="font-size: 20px;">Message Received!</h1>
                <p class="text-white">Thank you for your message. We will research your question and get back to you quickly.</p>
              </div>

              <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">

                <b-form-group
                  id="name-group"
                  label="Full Name:"
                  label-for="name"
                  description=""
                >

                  <p v-show="validation.name == false" class="small" style="color: #ffff80; margin-bottom: 5px;">
                    <b-icon-info-circle-fill style="height: 18px; width: 18px; padding-top: 3px;"></b-icon-info-circle-fill>
                    Please enter your name.
                  </p>
                  <b-form-input
                    id="name"
                    :class="{ 'error' : validation.name == false }"
                    v-model="form.name"
                    placeholder=""
                    trim
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="role-group" label="Role:" label-for="role-group">
                  <p v-show="validation.role == false" class="small" style="color: #ffff80; margin-bottom: 5px;">
                    <b-icon-info-circle-fill style="height: 18px; width: 18px; padding-top: 3px;"></b-icon-info-circle-fill>
                    Please select your role.
                  </p>
                  <b-form-select
                    id="role-group"
                    v-model="form.role"
                    :class="{ 'error' : validation.role == false }"
                    :options="roles"
                  ></b-form-select>
                </b-form-group>

                <b-form-group id="dealership-name-group" label="Dealership name:" label-for="dealership-name-group">
                  <b-form-input
                    id="dealership-name"
                    v-model="form.dealership_name"
                    placeholder=""
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="dealership-code-group" label="Dealership code:" label-for="dealership-code-group">
                  <b-form-input
                    id="dealership-code"
                    v-model="form.dealership_code"
                    placeholder=""
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="phone-group" label="Phone number:" label-for="phone-group">
                  <p v-show="validation.phone == false" class="small" style="color: #ffff80; margin-bottom: 5px;">
                    <b-icon-info-circle-fill style="height: 18px; width: 18px; padding-top: 3px;"></b-icon-info-circle-fill>
                    Please enter a valid phone number.
                  </p>
                  <b-form-input
                    id="phone"
                    v-model="form.phone"
                    placeholder=""
                    type="tel"
                    :class="{ 'error' : validation.phone == false }"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="email-group" label="Email:" label-for="email-group">
                  <p v-show="validation.email == false" class="small" style="color: #ffff80; margin-bottom: 5px;">
                    <b-icon-info-circle-fill style="height: 18px; width: 18px; padding-top: 3px;"></b-icon-info-circle-fill>
                    Please enter a valid email address.
                  </p>
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    placeholder=""
                    type="email"
                    :class="{ 'error' : validation.email == false }"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="message-group" label="Message:" label-for="message-group">
                  <p v-show="validation.message == false" class="small" style="color: #ffff80; margin-bottom: 5px;">
                    <b-icon-info-circle-fill style="height: 18px; width: 18px; padding-top: 3px;"></b-icon-info-circle-fill>
                    What would you like to say?
                  </p>
                  <b-form-textarea
                    id="message"
                    v-model="form.message"
                    placeholder=""
                    rows="4"
                    :class="{ 'error' : validation.message == false }"
                  ></b-form-textarea>
                </b-form-group>

                <b-button v-show="!processing" block type="submit" class="btn btn-outline-light">Send</b-button>
                <b-button v-show="processing" disabled block type="submit" class="btn btn-outline-light"><b-spinner class="mr-2" small></b-spinner> Sending...</b-button>

              </b-form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </the-modal>

</template>

<script>
import axios from "axios";
import TheModal from '@/components/TheModal.vue';

export default {
  
  name: "the-support",

  components: {
    TheModal,
  },

  data() {
    return {
      processing: false,
      success: false,
      form: {
        name: '',
        role: null,
        dealership_name: '',
        dealership_code: '',
        phone: '',
        email: '',
        message: '',
        origin: this.$route.name,
        ipAddress: '',
      },
      roles: [{ text: '-- select your role --', value: null, disabled: true, hidden: true }, 'General Manager', 'Internet Manager', 'Dealer Admin Support', 'Dealer Partner', 'Honda Digital Dealer Partner', 'Other'],

      validation: {
        name: null,
        role: null,
        dealership_name: true,
        dealership_code: true,
        phone: null,
        email: null,
        message: null,
      }
    }
  },

  mounted () {
    axios
      .get(process.env.VUE_APP_CLIENT_IP)
      .then(resp => (this.form.ipAddress = resp.data))
  },

  methods: {

    onSubmit() {
      this.$store.commit("generateApiCreds");

      var self = this;
      
      this.validateForm()

      if(!this.validateForm()){
        return false;
      }

      this.processing = true;

      const formData = new FormData();

      for (let [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }

      axios
        .post(process.env.VUE_APP_API_PATH + "inquiries", formData, {
            auth: {
              username: this.$store.state.auth.apiUsername,
              password: this.$store.state.auth.apiPassword,
            },
          })
        .then(function(resp) {
          if(resp.status === 200){
            self.resetForm();
            self.processing = false;
            self.success = true;
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
        // .catch(function() {
        //   alert('We have an error on our site. Please contact support and let us know. Thanks!')
        // });
    },

    validateForm(){

      this.validation.name            = this.form.name.length > 3 ? true : false;
      this.validation.role            = this.form.role ? true : false;
      this.validation.dealership_name = true;
      this.validation.dealership_code = true;
      this.validation.phone           = this.form.phone.length > 6 ? true : false;
      this.validation.email           = this.form.email.length > 6 ? true : false;
      this.validation.message         = this.form.message.length > 6 ? true : false;

      return Object.values(this.validation).every(Boolean);
    },

    resetForm() {
      this.form.name = '';
      this.form.role = null;
      this.form.dealership_name = '';
      this.form.dealership_code = '';
      this.form.phone = '';
      this.form.email = '';
      this.form.message = '';
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    },

  },

  computed: {

  },

};

</script>

<style scoped>

  .btn-outline-light {
    background-color:rgba(0, 0, 0, .2);
    border: 1.4px solid rgba(255, 255, 255, .8);
    font-weight: 400;
    border-radius: 30px;
    transition: none;
  }

  .btn-outline-light:hover {
    background-color:rgba(0, 0, 0, .5);
    color: white;
    transition: none;
  }

  .btn-outline-light:active {
    background-color:rgba(0, 0, 0, .8);
    color: white;
    transition: none;
  }

  .btn-outline-light:focus {
    background-color:rgba(0, 0, 0, .8);
    color: white;
    transition: none;
  }

  .btn.disabled {
    cursor:not-allowed;
    background-color:rgba(0, 0, 0, .8);
    border: 1.4px solid rgba(255, 255, 255, .8);
    color: white;
    transition: none;
  }

  .form-control {
    color: white;
    background-color: #236494;
    border: 1.3px solid rgba(255, 255, 255, .8);
  }

  .form-control:focus {
    color: white;
    background-color: #134a72;
    border: 1.5px solid rgba(255, 255, 255, 1);
  }

  .custom-select {
    color: white;
    background: url('../assets/br_down.png') no-repeat right;
    padding-right: 5px;
    background-color: #236494;
    background-position-x: 95%;
    border: 1.3px solid rgba(255, 255, 255, .8);
  }

  .custom-select:focus {
    color: white;
    background-color: #134a72;
    border: 1.5px solid white;
  }

  .error {
    border: 1.5px solid #ffff80;
    background-color:rgba(0, 0, 0, .2);
  }

  #close-x {
    float: right;
    color: white;
    z-index: 110;
    height: 35px;
    width: 35px;
    opacity: .5;
    margin-top: -5px;
  }

  #close-x:hover {
    cursor: pointer;
    opacity: 1;
  }

  @media screen and (min-width: 769px) {

    .left-corners {
      border-radius: 5px 0px 0px 5px;
    }

    .right-corners {
      border-radius: 0px 5px 5px 0px;
    }

  }

  @media screen and (max-width: 768px) {

    #close-x {
      float: right;
      color: white;
      z-index: 110;
      height: 35px;
      width: 35px;
      opacity: .5;
      margin-top: -40px;
    }

  }

</style>
