<template>

  <b-container @click="closeNavDropdowns" fluid style="padding-bottom: 100px;" :class="{ 'pos-absolute': modalActive }">

    <the-hamburger class="text-white d-md-none" style="position: fixed; z-index: 220; right: 15px; top: 10px;"></the-hamburger>
    <the-support style="width: 100%; position: absolute; z-index: 350;" v-if="supportActive"/>
    <the-overlay v-bind:style="{ height: this.$store.state.windowHeight + 'px' }" class="w-100" v-show="modalActive"/>
    <the-blue-bar class="d-none d-md-block"></the-blue-bar>
    <the-nav/>
    <the-sub-nav :newsletterUrl="newsletterUrl" :enrollmentUrl="enrollmentUrl"></the-sub-nav>
    <the-admin-bar v-show="loggedIn"></the-admin-bar>
    <router-view :newsletterUrl="newsletterUrl"/>
    <the-footer/>

  </b-container>

</template>

<script>

import TheHamburger from '@/components/TheHamburger.vue'
import TheSupport from '@/components/TheSupport.vue'
import TheOverlay from '@/components/TheOverlay.vue'
import TheBlueBar from '@/components/TheBlueBar.vue'
import TheAdminBar from '@/components/TheAdminBar.vue'
import TheNav from '@/components/TheNav.vue'
import TheSubNav from '@/components/TheSubNav.vue'
import TheFooter from '@/components/TheFooter.vue'

export default {
  name: 'app',

  // vue-meta plugin stuff is injected here
  metaInfo: {
    //  title: 'Honda Digital Customer Experience',
     titleTemplate: '%s | Honda Digital Customer Experience'
   },

  components: {
    TheHamburger,
    TheSupport,
    TheOverlay,
    TheBlueBar,
    TheAdminBar,
    TheNav,
    TheSubNav,
    TheFooter
  },

  data() {
    return {
      newsletterUrl: '',
      enrollmentUrl: this.$store.state.data.enrollmentPortalUrl,
    };
  },

  watch: {
    '$route.params': function ()
    {
      this.getNewsletterUrl();
    },
  },

  created() {
    this.checkForceUpdate();
    this.getNewsletterUrl();
  },

  methods: {

    getNewsletterUrl() {

      let self = this;
      this.$store.commit('generateApiCreds');
      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'newsletters', {
          // auth: {
          //   username: this.$store.state.auth.apiUsername,
          //   password: this.$store.state.auth.apiPassword,
          // }
        })
        .then(resp =>
        {
          self.newsletterUrl = resp.data[resp.data.length - 1].url;
        })
    },

    checkForceUpdate() {
      if (this.$route.query.update) {
        // console.log('Forcing an update...');
        this.$root.$forceUpdate();
      }     
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    },

    closeNavDropdowns({ target }) {
      if(target.classList[0] != 'nav-link') {
        this.$store.commit("closeNavDropdowns");
      }
    },

  },

  computed: {
    
    modalActive() {
      return this.$store.state.modalActive ? true : false;
    },

    supportActive() {
      return this.$store.state.supportActive ? true : false;
    },

    loggedIn() {
      return this.$store.getters.loggedIn ? true : false;
    },

    showSubNav() {
      return this.$route.query.subnav ? true : false;
    },
  }

}

</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');

  .container-fluid {
    font-family: 'Lato', sans-serif;
  }

  .pos-absolute {
    position: absolute;
  }

  .btn-delete {
    color: #dc3545;
    padding: 5px;
  }

  .btn-delete:hover {
    color: white;
    border-radius: 5px;
    background-color: #dc3545;
    text-decoration: none;
  }

  .pre-line {
    white-space: pre-line;
  }

@import 'assets/css/subnav.css' 

</style>
